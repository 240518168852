import { Dropdown } from 'antd'
import { useEffect, useState } from 'react'

import TypesDropdown from './TypesDropdown'
import DisplayDropdown from './DisplayDropdown'
import { ResourcesActions } from '../../../types'
import { useParams } from 'react-router-dom'
import { ButtonLg } from '../../../../../Buttons/ButtonLg'
import Divider from '../../../../../Divider'
import { getActiveGroupsWithChilds } from '../../../../../../../utils/requests'

type Props = ResourcesActions
export default function ResourcesActionsComp({filter, display, bar}: Props) {
    const [loading, setloading] = useState(false)
    const [publicationsTypes, setpublicationsTypes] = useState([]);
    const [filterDropdown, setfilterDropdown] = useState(false)
    const params = useParams()
    
    useEffect(() => {
        (async() => {
            setloading(true)
            if(!publicationsTypes || publicationsTypes?.length === 0){
                console.log('ex')
                const data = await fetch(getActiveGroupsWithChilds).then(res => res.json())
                setpublicationsTypes(data.data)
            }
            setloading(false)
        })()
    }, []) 


  return (
    <div className='flex items-center no-dragable space-x-3'>
          <div className='flex space-x-2 items-center'>
                {/* <Dropdown
                    overlay={(!filter) ? <></> : <TypesDropdown selectedType={filter.selectedPubType} selectAction={() => {}} typesList={publicationsTypes}/>}
                    trigger={['click']}
                    disabled={true}
                    >
                       
                        <button>
                                <ButtonLg
                                    suffix={{
                                        icon:{type:'icon', src: 'ChevronDownIcon' },
                                        size: 'mini'
                                    }}
                                    buttonProps={{
                                            disabled: true
                                    }}
                                    prefix={{
                                        icon:{type:'icon', src: 'AddDocument' },
                                        size: 'sm'
                                    }}
                                ><>New</></ButtonLg>

                            </button>
                                                                        
                        
                    </Dropdown> */}
                    {/* <Divider/> */}
                 
                <Dropdown 
                overlay={(!filter) ? <></> : <TypesDropdown selectedType={filter.selectedPubType} selectAction={(node:any) => filter?.setSelectedPubType({ value: node.id, icon: node.iconUrl, type:'type' , title:node.name_plural.en})} typesList={publicationsTypes}/>} trigger={['click']} disabled={!!(loading || params.group)} 
                onVisibleChange={visible => setfilterDropdown(visible)}
                >
                    {/* <Spin spinning={loading}> */}
                    <button>
                        <ButtonLg
                            suffix={{
                                icon:{type:'icon', src: filter?.selectedPubType?.value ? 'XCircleIcon' : 'ChevronDownIcon'},
                                size: 'mini',
                                onClick:() => filter?.selectedPubType.value ? filter?.setSelectedPubType({value:'', icon: '', type:'type', title: ''}) : {}
                            }}
                            prefix={{
                                icon:filter?.selectedPubType?.value ? {type:'image', src: filter?.selectedPubType?.icon} : {type:'icon', src: 'FilterIcon'},
                                size: 'sm'
                            }}
                            buttonProps={{
                                disabled:!!(loading || !filter || params.group)

                            }}
                        ><>{filter?.selectedPubType?.value? filter.selectedPubType.title :'Filter'}</></ButtonLg>

                    </button>
                   

                </Dropdown>
                
            

                </div>
                <Divider/>
            
                    <Dropdown
                    overlay={!display ? <></> : <DisplayDropdown setGroupBy={display.setGroupBy} groupBy={display.groupBy} displayMode={display.displayMode} setDisplayMode={display.setDisplayMode} barActivatedFields={bar?.barActivatedFields} setBarActivatedFields={bar?.setBarActivatedFields} /> }
                    trigger={['click']}
                    disabled={(!display)}
                    >
                           <button>
                                <ButtonLg
                                    suffix={{
                                        icon:{type:'icon', src: 'ChevronDownIcon' },
                                        size: 'mini'
                                    }}
                                    buttonProps={{
                                        disabled: (!display)
                                    }}
                                    prefix={{
                                        icon:{type:'icon', src: display?.groupBy ? 'GroupBy' : 'Degroup' },
                                        size: 'sm'
                                    }}
                                ><>Diplay</></ButtonLg>

                            </button>
                                                                        
                        
                    </Dropdown>

              


    </div>
  )
}
