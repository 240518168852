import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router'
import { Drawer, Spin } from 'antd'
import { useAppContext } from '../../../context/appContext';
import PaginationComponent from '../../../components/shared/pagination';
import ResourcesActionsComp from '../../../components/shared/bars/TopBar/actionsBar/actions/resources';
import { Search } from '../../../components/shared/input/Search';
import { ArrowLeftIcon } from '@heroicons/react/solid';



export default function ResourcesContainerLayout() {
    const { query, setQuery, pagination, setPaginate, totalHits, clearSearch, selectedPubType, setSelectedPubType, groupBy } = useAppContext()
    const [loading, setloading] = useState(false);
    const navigate = useNavigate()
    const [all, setall] = useState(false);
    const location = useLocation()

    useEffect(() => {
      
      if(location.pathname.includes('partner')){
        setall(true)
      }else{
        setall(false)
      }
    }, [location.pathname]);
   
     
      

 

      useEffect(() => {
        setTimeout(() => {
          clearSearch()
          
        }, 1000);
      }, []);

   

    

     
  return (
   
   

            <>
            
               
              <div className="bar-h flex items-center space-x-3 ">
             
                
                <div className="flex-1 flex px-5 justify-between">
                  <div className="flex">
                    <button className='p-1.5 bg-hover-transparent rounded no-dragable disabled:fill-skin-muted' onClick={() => navigate(-1)} >
                      <ArrowLeftIcon className='icon-sm'/>
                  </button>
                    <ResourcesActionsComp
                        filter={{
                          selectedPubType,
                          setSelectedPubType
                        }}
                    />
                  </div>
                 <Search
                    placeholder='Search'
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    className='bg-input rounded min-w-[250px] border border-main'
                  />

                </div>
              </div>
              
                <div className="flex-1 bg-sub-windows pl-[6px] flex ">
               
                  <div className='flex-1'>
                    <Spin spinning={loading}>
                     <Outlet/>

                    </Spin>

                  </div>

                </div>
                <div className="border-t border-main">
                   <div className="flex justify-between bar-h items-center px-5">
                    <div className="flex-1 flex items-center space-x-4">
                      
                     {totalHits.pubs ? (
                      <p>{totalHits.pubs} Publications</p>
                     ) : ''}
                     {totalHits.webresources ? (
                      <p className='flex'>{totalHits.webresources} Webpages</p>
                     ) : ''}
                     <div className="flex-1">

                      <PaginationComponent
                          disabled={false}
                          pagination={pagination}
                          totalHits={totalHits}    
                          hidePanel={false}
                          setPaginate={setPaginate}
                          allowPaginate={{
                            webresources: !groupBy.webresources,
                            pubs: !groupBy.paidPubs && !!selectedPubType.value,
                            partnerPubs: all,
                          }}
                      />
                     </div>

                    
                      
                    </div>
                   </div>

                </div>
            </> 

  )
}
