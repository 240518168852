import React, { useEffect, useState } from 'react'
import { Highlight, Publication } from '../types';
import { searchPublications } from '../services/search';
import { useAppContext } from '../context/appContext';


export default function useGetPublications({id, limit, sponsored, groupBy, mode, extraFilter, partner}:{id?:string, limit?:number, sponsored?:boolean, groupBy:boolean, mode?:'roots'|'volume', extraFilter?:string, partner?:boolean}) {
    const [publications, setpublications] =  useState<undefined | {document:Publication, highlight:Highlight}[]>(undefined)
    const { query, selectedPubType, setTotalHits, setGroupBy, pagination, setPaginate, setSelectedPubType } = useAppContext()
    const [newRequest, setnewRequest] = useState(0)
    const [isLoaded, setisLoaded] = useState(true);
    useEffect(() => {
        (async()=>{ 
            if(id){
                const res:any = await searchPublications({q:query, volumeId:id, type:{value:selectedPubType.value, type:selectedPubType.type}, limit: (limit && !(selectedPubType.value || query)) ? limit: pagination.limit, offset:pagination.offset, page: pagination.page, mode,sponsored, groupBy: groupBy ? 'publicationGroupe.id, publicationGroupe.title.en' : undefined, extraFilter})
                if(res.grouped_hits){
                    setGroupBy({paidPubs:true})
                    setpublications(res?.grouped_hits)
                    if(partner){
                      setTotalHits({partnerPubs: res.found_docs})
                      
                    }else{
                      setTotalHits({pubs: res.found_docs})
                    }
                }else{
                    setGroupBy({paidPubs:false})
                    if(partner){
                      setTotalHits({partnerPubs: res.found})
                      
                    }else{
                      setTotalHits({pubs: res.found})
                    }
                    setpublications(res?.hits)
                }
                
                
                if(selectedPubType.value){
                  setTotalHits({webresources: null})
                }

            }
        })()
      }, [query, id, selectedPubType.value, selectedPubType.type, groupBy, pagination.offset, pagination.page, newRequest, mode, extraFilter, partner])
      useEffect(() => {
        setisLoaded(false)
        setTimeout(() => {
            setnewRequest(prev => prev+1)
            setisLoaded(true)
        }, 100);
      }, [pagination.limit, groupBy])

      useEffect(() => {
        setSelectedPubType({type: 'type', value: '', icon:'', title:''})
        setPaginate({page: 1, offset:0})
      }, [groupBy]);
  return {
    publications,
    isLoaded
  }
}
