

import TopBarIcons from './TopBarIcons'
import TopBarTitle from './TopbarTitle'
import { TitleProps } from '../types';

import { XIcon } from '@heroicons/react/solid';
import Icon from '../../../../icons/Icon';


export default function TitleBar(props: TitleProps ) {


 
  return (
    <div className='bg-mainbar '>

      <div className="bg-bars flex justify-between menubar" >
        <div className={`flex flex-1 overflow-hidden relative pr-[20px] space-x-2 ${!props.NonewWin && 'pt-[3px]'}` } >
          {props.topbarTitle && (
            <div className='flex flex-1 space-x-[2px] ml-[21px] scroll-hor' >
                {      props.tabLists && props.tabLists.map((tab,i) => ( 
                        <div className='flex'>
                          <div className={`${(!props.NonewWin  && tab.active)? 'bg-topbar bg-sub-windows' : 'text-white fill-white '}  flex items-center  rounded-t no-dragable px-3 space-x-2.5 group`} key={tab.id} >
                            <button className={`${tab.main && 'cursor-default menubar'}`} onClick={() => {(props.setTab && !tab.main) && props.setTab(tab.id)}}>
                              <TopBarTitle
                                NonewWin={props.NonewWin}
                                title={tab.title}
                                icon={tab.icon}
                            
                              />

                            </button>
                          {(props.removeTab && !tab.main) && (
                            <button className={`w-[20px] flex items-center justify-center h-max my-auto p-1 bg-hover-transparent rounded`} onClick={() => props.removeTab && props.removeTab(tab.id)}>
                                <XIcon className={`opacity-0 group-hover:opacity-[1] ${tab.active && '!opacity-[1]'} icon-mini`}/>
 
                            </button>
 
                          )}

                           
                          </div>
                          <div className='w-[1px] h-[15px] mx-4 my-auto bg-white opacity-[0.2]'></div>
                          {i === props.tabLists!.length - 1 && (

                            !props.NonewWin && props.topbarTitle && props.createTab && (
                              <button className='w-[45px] h-full text-white fill-white rounded-t flex justify-center items-center no-dragable' onClick={() => props.createTab && props.createTab({title: props.topbarTitle!.title, icon: props.topbarTitle!.icon})}>
                                <Icon className='icon-mini' name='PlusIcon'/>
                              </button>

                            )
                          )}
                        </div>
                      ))}


            </div>
          )}
       
        

        </div>
          <div className="flex space-x-3 bar-h menubar items-center px-1.5  text-white fill-white">
              
             
              <TopBarIcons
                    actions={{
                        close: props.close 
                    
                    }}
                    main={props.main}
                  
                />
            </div>

        </div>
    </div>
  )
}
