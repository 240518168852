import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router'
import { Drawer, Spin } from 'antd'
import WindowLayout from '../../components/shared/WindowLayout';
import { Search } from '../../components/shared/input/Search';
import { useAppContext } from '../../context/appContext';
import { getVolum, getVolumWithPartners } from '../../utils/requests';
import PaginationComponent from '../../components/shared/pagination';
import { getVolume } from '../../services/search';
import Icon from '../../components/icons/Icon';
import useNavigateTo from '../../hooks/useNavigateTo';
import ResourcesActionsC from '../../components/shared/bars/TopBar/actionsBar/actions/resources';
import useFetchVolum from '../../hooks/useFetchVolum';
import ReactSvg from '../../components/shared/ReactSvg';
import { storageUrl } from '../../constants/apiRequests';


export default function WidgetLayout() {
    const [visible, setvisible] = useState(false);
    const params = useParams()
    const { containerRef,setWindowHeight, setresourcesWidth, setDomain } = useAppContext()
    const location = useLocation()
    const { volume, loading } = useFetchVolum(params.id, params.coll!)
    const { volume:domain, loading:loading2 } = useFetchVolum(params.datacenterId, 'Domains')
    const navigate = useNavigate()
    const [fullWidgetLink, setfullWidgetLink] = useState('');

    useEffect(() => {
        setvisible(true)
        setWindowHeight(containerRef?.current?.clientHeight)
        setresourcesWidth(containerRef?.current?.clientWidth)
    }, [containerRef?.current]);

    useEffect(() => {
      if(domain?.id){
        setDomain(domain)

      }else{
        setDomain(null)
      }
    }, [domain?.id]);
    useEffect(() => {
      
      if(domain?.subdomains && volume){
        const host = domain.subdomains.filter((h:string) => h.includes('qorddu'))?.[0]
        if( host && params.coll !== 'LocalClusters'){
          setfullWidgetLink(`https://${host}/${volume.clusterId}/${params.coll}/${params.id}`)
        }else if(host){
          setfullWidgetLink(`https://${host}/${params.coll}/${params.id}`)
        }
      }
    }, [domain, volume]);
  
   



 

   



     
  return (
   
    <Drawer
        footer={null}
        visible={visible}
        closable={false}
        width={'800px'}
        placement='left'
        style={{
            height: 'calc(90vh)',
            top: '10vh',
            left:0,
        }}
        // getContainer={() => rootRef?.current}
    >

        <WindowLayout>
          <Spin spinning={loading || loading2}>
            <div className="flex flex-col h-full overflow-hidden bg-sub-windows " ref={containerRef}>
              {domain && volume && (
                <>
                  <div className='flex pl-[25px] pr-3 bar-h justify-between items-center'>
                    <div className='flex space-x-3 items-center'>
                      <ReactSvg src={`${storageUrl}${domain?.icon?.src}`} className='icon-sm'/>
                      <p className="font-bold">{domain?.title}</p>
                    </div>
                    <div className='flex space-x-3'>
                      <a href={fullWidgetLink} target='_blank' className='p-1 rounded  !text-inherit'>
                        <Icon name='OpenWindow' className='icon-mini'/>
                      </a>
                      <button className='p-1 rounded bg-red-600 text-white' onClick={() => navigate('/')}>
                        <Icon name='Close' className='icon-mini'/>
                      </button>

                    </div>
                </div>
                <div className='bar-h pl-[25px] flex items-center space-x-4 border-b border-bottom'>
                  <button onClick={() =>navigate(`widget/main/${params.datacenterId}/${params.coll}/${params.id}`)} className={`${location.pathname.includes('main') && 'active-mode'}`}>
                      {volume?.title}
                  </button>
                  <button onClick={() =>navigate(`widget/global/${params.datacenterId}/${params.coll}/${params.id}`)} className={`${location.pathname.includes('global') && 'active-mode'}`}>
                      World
                  </button>
                </div>
                
                </>
              )}
              <Outlet/>
            </div> 

          </Spin>

        </WindowLayout>
    </Drawer>
  )
}
