import { apiEndPoint } from "../constants/apiRequests"

export const getDatacenterLocalServers = ({ id}: {id:string}) => `${apiEndPoint}/volums/specializeds/${id}/servers`

export const getServerTree = ({ id}: {id:string}) => `${apiEndPoint}/volums/specializeds/${id}/tree`

export const getDatacenterPartners = ({ id}: {id:string}) => `${apiEndPoint}/volums/specializeds/datacenters/${id}/partners/get?grouped=true`

export const getVolumWithPartners = ({ id, coll}: {id:string, coll:string}) => `${apiEndPoint}/collaborators/${id}/${coll}/collaborators/${coll === 'Datacenters' ? '?datacenter=true' : ''}`

export const getActiveGroupsWithChilds = `${apiEndPoint}/publications-types/all`


export const getDirectoryDetails = ({ id, orgId }: {id:string, orgId: string|undefined}) => `${apiEndPoint}/volum/specializeds/${id}/details?orgId=${orgId}`

export const isPinned = ({id} : {id:string}) => `${apiEndPoint}/pinned/${id}/check`

export const getPinneResources = ({type}:{type:string}) => `${apiEndPoint}/pinned/${type}`
export const pinResource = ({type}:{type:string}) => `${apiEndPoint}/pinned/${type}`
export const unpinResource = ({type, id}:{type:string, id:string}) => `${apiEndPoint}/pinned/${type}/${id}`

export const getGeographicTree = `${apiEndPoint}/volums/geographics`
export const getGeographicServers = ({countryId} : {countryId:string}) => `${apiEndPoint}/volums/geographics/${countryId}`


export const getAllDomains = `${apiEndPoint}/volums/specializeds/univers/nodes/childrens`
export const getNodeDomains = ({univerId, nodeId, featuredDomain}:{univerId:string, nodeId:string, featuredDomain:string}) => `${apiEndPoint}/volums/specializeds/${univerId}/${nodeId}/${featuredDomain}/domains`
export const getTree = ({id, geo} : {id:string, geo?:boolean}) => `${apiEndPoint}/volums/specializeds/domains/${id}/childrens${geo ? '?geograohic=true' : ''}`
export const getClusterTree = ({id} : {id:string}) => `${apiEndPoint}/volums/specializeds/clusters/${id}/childrens`
export const getDomainCollaborators = ({domainId}:{domainId:string}) => `${apiEndPoint}/collaborators/${domainId}`
export const getDatacenterCollaborators = ({datacenterId}:{datacenterId:string}) => `${apiEndPoint}/collaborators/${datacenterId}/datacenters`

export const getVolumCollaborators = ({volumId, volumColl}:{volumId:string, volumColl:string}) => `${apiEndPoint}/collaborators/${volumId}/${volumColl}/collaborators/${volumColl === 'Datacenters' ? '?datacenter=true' : ''}`
export const getVolumOwner = ({volumId, volumColl}:{volumId:string, volumColl:string}) => `${apiEndPoint}/collaborators/${volumId}/${volumColl}/owner`

export const getVolum = ({volumId, volumColl}:{volumId:string, volumColl:string}) => `${apiEndPoint}/volums/specializeds/${volumColl}/${volumId}`
