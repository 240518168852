import React, { useEffect, useState } from 'react'
import { PartnerType } from '../types'
import { getVolumCollaborators } from '../utils/requests'



export default function useGetCollaborators({setloading, id, coll, suffix} : {setloading:any, id:string, coll:string, suffix?:string}) {
    const [sponsors, setsponsors] = useState<null | PartnerType[]>(null)
    const [partners, setpartners] = useState<null | PartnerType[]>(null)



    useEffect(() => {
        if( id && coll){
            setloading(true)
            fetch(getVolumCollaborators({volumId: id, volumColl:coll})).then((res) => res.json()).then(data => {
              
                setsponsors(data.data?.sponsors)
                setpartners(data.data?.partners)

                
            }).catch(err => console.log(err)).finally(() => setloading(false))
  
        }
    }, [id, coll])
  return {
    sponsors,
    partners
  }
}
