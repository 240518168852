import { Spin, Tree } from 'antd'
import React, { useEffect, useRef, useState } from 'react'


import TreeLayout from './Layout';
import { iconType, Server } from '../../../../../../types';
import { getTree } from '../../../../../../utils/requests';
import { storageUrl } from '../../../../../../constants/apiRequests';




// const reRenderTree = (tree:any) => {
//     return tree.map((node:Server) => {
//         if (node.id === id) {
//             return {
//                 ...node,
//                 children:data,
//             };
//         }
//         if (node.children) {
//             return {
//                 ...node,
//                 children: loopThrough(node.children, id, data)
//             };
//         }
//         return node;
//     });
// }
const loopThrough = (tree:any, id:string, data:any) => {
  return tree.map((node:Server) => {
      if (node.id === id) {
          return {
              ...node,
              children:data,
          };
      }
      if (node.children) {
          return {
              ...node,
              children: loopThrough(node.children, id, data)
          };
      }
      return node;
  });
}
const generateIds = (tree:any[], id:string) => {
    return tree.map(el => {
        el.path = `/${id}${el.path}`
        if(el.children){
            generateIds(el.children, id)
        }
        return el
    })
}


type Props = {

    handleLocationSelection: (node:any) => void, 
    selectedKey:string,
    datacenter: Server | null, 
    settree: (data:Server[] | null) => void,
    tree: Server[] | null,
}


export default function TreeComponentWorld({ handleLocationSelection, selectedKey,datacenter, tree, settree}: Props) {

    const [loading, setloading] = useState(false);






  useEffect(() => {
    if(datacenter && tree?.length === 0){
        settree([])
        setloading(true)
        fetch(getTree({id: datacenter.id, geo: datacenter?.local})).then(res => res.json()).then(data =>{
             settree(data.data)
            //  setexpandedKeys(['0', '1', '2', '3', '4', '5'])
            //  setautoExpandParent(false)
        }).catch(e => console.log(e)).finally(() => setloading(false))
    }
    }, [datacenter?.id]);
 
 
  return (
    <>
  
        <Spin
            spinning={loading}
        >
            {tree && tree?.length > 0 && (

                 <TreeLayout
                    tree={
                        tree ? [
                            {
                                title:datacenter?.title, 
                                iconUrl:`${storageUrl}${(datacenter as any)?.icon?.src}`,
                                iconType: 'image',
                                id: '/',
                                key:'/',
                                path: '/',
                                // isLeaf:true,
                                children: tree
                            },
                            // ...generateBreadcrumbs(tree, null)
                        ] : []
                    }
                    handleLocationSelection={handleLocationSelection}
                    selectedKey={selectedKey}
                 
                 />
                
                )}

        </Spin>
          
    
    </>
  )
}
