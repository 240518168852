import ReactDOM from 'react-dom/client';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import Layout from './pages/Layout';
import 'antd/dist/antd.css';

import './styles/global.css'
import WidgetLayout from './pages/widget/Layout';
import { AppContextProvider } from './context/appContext';
import ResourcesLayout from './pages/widget/resources';
import AllPublicationsLayout from './pages/widget/resources/allPublications';
import PublicationDetails from './components/shared/details/Publications';
import ResourcesContainerLayout from './pages/widget/resources/Layout';

import PrivateRoute from './components/shared/PrivateRoute';
import Login from './pages/connect';
import AllPublicationsLayoutEngines from './pages/widget/resources/allPublications/engines';
import WorldLayout from './pages/widget/world/Layout';
import VolumePartners from './pages/widget/world/volume';
import Website from './pages/widget/resources/websitePublications';
import PartnerPublications from './pages/widget/resources/allPublications/partnerPublications';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
    <Route element={<PrivateRoute/>}>
      <Route element={<AppContextProvider><Layout/></AppContextProvider>}>
        <Route path='/' element={<></>}/>
      


       
        <Route element={<WidgetLayout/>}>                                 
          <Route path='/widget' element={<></>}/>



        
         
            <Route element={<WorldLayout/>}>
              <Route path='/widget/global/:datacenterId/:coll/:id' element={<></>}/>
              <Route path='/widget/global/:datacenterId/:coll/:id/:volumeColl/:volumeId' element={<VolumePartners/>}/>

            </Route>

       
          <Route element={<ResourcesContainerLayout/>}>
            <Route element={<ResourcesLayout/>}>
              <Route path='/widget/main/:datacenterId/:coll/:id' element={<></>}/>
                <Route path='/widget/main/:datacenterId/:coll/:id/publications/:publicationId' element={<PublicationDetails/>}/>
                <Route path='/widget/main/:datacenterId/:coll/:id/webpage/:webpageId' element={<PublicationDetails/>}/>
                {/* <Route path='/widget/main/:datacenterId/:coll/:id/volumes/:volumeId' element={<VolumeDetails/>}/> */}

            </Route>
            <Route element={<Website/>}>
              <Route path='/widget/main/:datacenterId/:coll/:id/webresources/:websiteId' element={<></>}/>
              <Route path='/widget/main/:datacenterId/:coll/:id/webresources/:websiteId/webpage/:webpageId' element={<PublicationDetails/>}/>
            </Route>
            <Route element={<PartnerPublications/>}>
              <Route path='/widget/main/:datacenterId/:coll/:id/partner/:partnerId' element={<></>}/>
              <Route path='/widget/main/:datacenterId/:coll/:id/partner/:partnerId/publications/:publicationId' element={<PublicationDetails/>}/>
            </Route>

            
            {/* <Route element={<PrivateEngineLayout type='privateDirectories'/>}>
              <Route path='/widget/main/:datacenterId/:serverId/engines/privateDirectories/:engineId' element={<></>}/>
              <Route path='/widget/main/:datacenterId/:serverId/:type/:volume/engines/privateDirectories/:engineId' element={<></>}/>

              <Route path='/widget/main/:datacenterId/:serverId/engines/privateDirectories/:engineId/publications/:publicationId' element={<PublicationDetails/>}/>
              <Route path='/widget/main/:datacenterId/:serverId/:type/:volume/engines/privateDirectories/:engineId/publications/:publicationId' element={<PublicationDetails/>}/>
                
              <Route path='/widget/main/:datacenterId/:serverId/engines/privateDirectories/:engineId/volumes/:volumeId' element={<VolumeDetails/>}/>
              <Route path='/widget/main/:datacenterId/:serverId/:type/:volume/engines/privateDirectories/:engineId/volumes/:volumeId' element={<VolumeDetails/>}/>
           
            </Route>

            <Route element={<PrivateEngineLayout type='privateFolders'/>}>
              <Route path='/widget/main/:datacenterId/:serverId/engines/privateFolders/:engineId' element={<></>}/>
              <Route path='/widget/main/:datacenterId/:serverId/:type/:volume/engines/privateFolders/:engineId' element={<></>}/>

              <Route path='/widget/main/:datacenterId/:serverId/engines/privateFolders/:engineId/publications/:publicationId' element={<PublicationDetails/>}/>
              <Route path='/widget/main/:datacenterId/:serverId/:type/:volume/engines/privateFolders/:engineId/publications/:publicationId' element={<PublicationDetails/>}/>
                
              <Route path='/widget/main/:datacenterId/:serverId/engines/privateFolders/:engineId/volumes/:volumeId' element={<VolumeDetails/>}/>
              <Route path='/widget/main/:datacenterId/:serverId/:type/:volume/engines/privateFolders/:engineId/volumes/:volumeId' element={<VolumeDetails/>}/>
         
           
            </Route> */}

          


            <Route element={<AllPublicationsLayout/>}>
              <Route path='/widget/main/:datacenterId/:serverId/all/publications' element={<></>}/>
              <Route path='/widget/main/:datacenterId/:serverId/:type/:volume/all/publications' element={<></>}/>
              {/**PUBLICATIONS DETAILS IN THE ALL PUBLICATIONS PAGE */}
                <Route path='/widget/main/:datacenterId/:serverId/all/publications/:publicationId' element={<PublicationDetails/>}/>
                <Route path='/widget/main/:datacenterId/:serverId/:type/:volume/all/publications/:publicationId' element={<PublicationDetails/>}/>

            </Route>


            <Route element={<AllPublicationsLayoutEngines/>}>
              <Route path='/widget/main/:datacenterId/:serverId/engines/:engineType/:engineId/all/publications' element={<></>}/>
              <Route path='/widget/main/:datacenterId/:serverId/:type/:volume/all/publications' element={<></>}/>
              {/**PUBLICATIONS DETAILS IN THE ALL PUBLICATIONS PAGE */} 
                <Route path='/widget/main/:datacenterId/:serverId/engines/:engineType/:engineId/all/publications/:publicationId' element={<PublicationDetails/>}/>
                <Route path='/widget/main/:datacenterId/:serverId/:type/:volume/engines/:engineType/:engineId/all/publications/:publicationId' element={<PublicationDetails/>}/>

            </Route>


          
           

          </Route>

        </Route>
      </Route>

    </Route>
    <Route path='/login' element={<Login/>}/>
    <Route path='/unauthorized' element={<p>Unauthorized</p>}/>

    
    </>

  )
);
root.render(
  <RouterProvider router={router} />
);
