

import ReactSvg from "../../../../components/shared/ReactSvg"
import { ServerWithHomeDesq } from "../../../../types"


export const Datacenter = ({dc, placeholder, active, handleNavigate, i, domain, select}: {dc:ServerWithHomeDesq, placeholder?:boolean,active?:boolean, handleNavigate?:(id:string) => void, i?:number, domain:string, select?:() => void}) => {
    
    return (
        <div className={`${!placeholder && 'shadow-desq'} border border-main w-[250px] hover:-translate-y-1 h-max rounded-[10px] transition-transform transition-shadow  relative group  bg-white cursor-pointer`}
        onClick={() => handleNavigate ? handleNavigate(dc.id) : {}}
      
        >
                {placeholder && (
                    <div className="absolute top-[5px] right-[-5px] w-full h-full shadow-desq border border-main rounded-[10px]"></div>
                )}
                <div className="w-full h-[140px] overflow-hidden relative z-[10] rounded-t-[10px]" >
                    {i && <div className="bg-black w-[22px] h-[22px] flex items-center justify-center absolute top-[5px] right-[5px] rounded-full">
                        <p className="text-small text-white">{i}</p>    
                    </div>}
                    <div className="w-[250px] h-[140px] relative rounded-[10px] bg-white">   
                   
                        {/* <GenerateTemplate
                            item={{
                                title:dc.title?.en ? dc.title.en :dc.title,
                                description: dc.description ? dc.description.en : '',
                                bg:dc.bg,
                                color:dc.color,
                                color2:dc.color2,
                                image:dc.image ? (!dc.image.includes('unsplash') ? [dc.image.split('?')[0], '_800x571','?',dc.image.split('?')[1] ].join('') : dc.image) : '/datacenter.jpg',
                                templatedId:dc.temp,
                                opacity:0.7,
                                status:dc.status,
                                domain:domain,
                            }}
                            dim='w-full h-full'
                        
                        /> */}
                    <div className={`overflow-hidden w-full h-full flex relative `} style={{background: dc.bg, color: dc.color}}>
                        <div className='max-w-[90px] flex-1 flex flex-col justify-center px-[10px] relative z-10'>
                            <p className='text-[7px] uppercase' style={{lineHeight: '9px', color:dc.color2}} >{domain}</p>

                            <p className='text-[9px] font-bold uppercase' style={{lineHeight: '12px'}}>{dc.title?.en ? dc.title.en :dc.title}</p>
                            <p className='text-[8px] ellipsis-4' style={{lineHeight: '10px'}}>{dc.description ? dc.description.en : ''}</p>
                        </div>
                        <div className="w-[60%] h-full absolute right-0" style={{clipPath:'polygon(29% 0, 100% 0%, 100% 100%, 0% 100%)'}}>
                            {!dc.image ? (
                                <div className="bg-skin-fill-muted w-full h-full flex justify-center items-center">
                                
                                </div>
                            ) : (
                                <img className='w-full h-full object-cover ' src={dc.image ? (!dc.image.includes('unsplash') ? [dc.image.split('?')[0], '_800x571','?',dc.image.split('?')[1] ].join('') : dc.image) : '/datacenter.jpg'}/>

                            )}
                        </div>
                    </div>
                  
                    </div>
                   
                </div>
                <div className="space-y-1.5 pt-[11px] pb-[10px] px-[15px] relative z-[10] bg-white rounded-b-[10px]">
                    <div className="flex space-x-2">
                        <ReactSvg src={`${dc.iconUrl}`} className="icon-sm mt-0.5"/>
                        <div className="space-y-0.5 flex-1">
                        
                            <p className="text-title truncate">{dc.title?.en ? dc.title.en :dc.title}</p>
                            {dc.status !== 'enabled' ? (
                                <p className={`${active ? 'text-small text-skin-inverted' : 'text-muted'} ellipsis-1`}>coming soon</p>

                            ) : (
                                <p className={`${active ? 'text-small text-skin-inverted' : 'text-muted'} ellipsis-1`} style={{lineHeight:'17px'}}>{dc.description?.en ? dc.description.en : 'Lorem ipsum dolor sit, amet consectetur adipisicing elit.'}</p> 
                            )}

                        </div>
                    </div>
                    <div className="flex justify-end">
                         
                                <button className="px-3 py-1.5 rounded border border-main bg-hover-transparent disabled:opacity-20" disabled={dc.status !== 'enabled'} onClick={select}>Select</button>

                            
                        


                 
                    </div>
                </div>
                

        </div>
    )
}