import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppContext } from '../../../../context/appContext'
import useGetWebResources from '../../../../hooks/useGetWebResources'
import { getPublication } from '../../../../services/search'
import LocationLayout from '../../components/resourcesLayout/Layout'
import useGetPublications from '../../../../hooks/useGetPublications'


export default function PartnerPublications() {
    const {  groupBy } = useAppContext()
    // const {disabled} = useUIContext()
    const params = useParams()
    const { publications, isLoaded } = useGetPublications({id: params.partnerId!, groupBy: false})
  
 

    
  return (
    <LocationLayout
      sponsors={null}
      publications={undefined}
      webResources={undefined}
      partnerPublications={publications}
      groupBy={groupBy}
      suffix={`${params.coll}/${params.id}/partner/${params.partnerId}/`}
      layoutProps={{
        loading:!isLoaded,
        collection: params.coll!,
        id: params.id!
      }}
      all={'Partner'}
      isLoaded={isLoaded}


    
    />
  )
}
