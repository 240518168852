import { useCallback } from 'react';

import useNavigateTo from './useNavigateTo';
import { Highlight, OwnerType, Publication } from '../types';

export const useSelectionHandlers = (suffix: string) => {
    const { goTo } = useNavigateTo();

    const handleSelectPublication = useCallback(
        (item: {document:Publication, highlight?:Highlight}, type?: 'webresource' | 'website') => {
            if (type === 'webresource') {
                goTo(`${suffix}webpage/${item.document.id}`, { state: { url: item.document.url, item:item.document, highlight: item.highlight } });
            } else if(type === 'website') {
                goTo(`${suffix}website/${item.document.id}`, { state: { url: item.document.url, item:item.document, highlight: item.highlight } });
            }else{
                goTo(`${suffix}publications/${item.document.id}`, { state: { url: item.document.url, item:item.document, highlight: item.highlight } });
            }
        },
        [suffix]
    );

    const handleSelectGroupedWebresources = useCallback(
        (id: string) => {
            goTo(`${suffix}webresources/${id}`, {});
        },
        [suffix]
    );

    const handleSelectGroupedPublication = useCallback(
        (id: string) => {
            goTo(`${suffix}group/${id}`, {}, true);
        },
        [suffix]
    );

    const handleSelectSeeAllPartnerPublications = useCallback(
        (partnerId: string) => {
            goTo(`${suffix}partner/${partnerId}`, {});
        },
        [suffix]
    );

    // const handleSelectPartner = useCallback(
    //     (item: PartnerType) => {
    //         goTo(`/${suffix}partners/${item.id}`, { state: { url: item.webResources.website, item } });
    //     },
    //     [suffix]
    // );
    const handleSelectOwner = useCallback(
        (item: OwnerType) => {
            goTo(`${suffix}owner/${item.id}`, { state: { url: item.website, item } });
        },
        [suffix]
    );

  

  
    return {
        handleSelectPublication,
        handleSelectGroupedWebresources,
        handleSelectGroupedPublication,
        handleSelectOwner,
        handleSelectSeeAllPartnerPublications
    };
};
