import React, { useState } from 'react'
import useGetCollaborators from '../../../hooks/useGetCollaborators'
import { useParams } from 'react-router'
import { Spin } from 'antd';
import PartnerWithWebsite from '../../../components/shared/cards/partners/PartnerWithWebsite';
import useGetLocations from '../../../hooks/useGetLocations';
import ObjectMd from '../../../components/shared/object/objectMd';
import ReactSvg from '../../../components/shared/ReactSvg';
import { storageUrl } from '../../../constants/apiRequests';
import { useAppContext } from '../../../context/appContext';

export default function VolumePartners() {
    const params = useParams()
    const [loading, setloading] = useState(false);
    const { partners, sponsors} = useGetCollaborators({id: params.volumeId!, coll: params.volumeColl!, setloading})
    const { totalHits, locations } = useGetLocations({
        id:params.volumeId!,
        extraFilter:   `cfs_type:!=[RegionalServers, ThematicServers, DepartmentDrive, LocalClusters]${params.volumeColl === 'LocalClusters' ? '' : `&& parentsIds:=${params.volumeId}`}`
    })
    const { domain } = useAppContext()
  return (
    <Spin spinning={loading}>
        <div className="space-y-[60px] pt-5 px-5 height-with-2bars overflow-auto">
            {partners?.[0] && (
                <div className="space-y-2">
                    <p className='text-groupe'>Partners</p>
                    <a href={`https://widget.teddra.com/widget/main/${domain?.id}/${partners[0].volums?.[0]?.volum_coll}/${partners[0].volums?.[0]?.volum_id}?url=${partners[0].webResources.website}&path=/${domain?.id}/${partners[0].volums?.[0]?.volum_coll}/${partners[0].volums?.[0]?.volum_id}`} target='_black' className='!text-inherit'>
                        <PartnerWithWebsite partner={partners[0]}/>
                    </a>
                </div>

            )}
            {sponsors && sponsors.length > 0 && (
                <div className="space-y-2">
                    <p className='text-groupe'>Sponsors</p>
                    <div className='flex flex-wrap gap-7'>
                        {sponsors.map(sponsor => (
                            <div key={sponsor.id} className='space-y-2.5 w-[130px]'>
                                <div className='w-full h-[130px] border border-main'>
                                    <img src={sponsor.logo} alt="" className='w-full h-full ' />
                                </div>
                                <div>
                                    <p className="font-bold">{sponsor.name}</p>
                                    <p className='ellipsis-3 text-muted'>
                                        {sponsor.description.en}
                                    </p>
                                </div>
                            </div>

                        ))}

                    </div>
                </div>

            )}
            {totalHits > 0 && (
                <div className="space-y-2">
                    <p className='text-groupe'>Dedicated volumes</p>
                    <div className='grid grid-cols-2 gap-2'>
                        {locations.map(location => (
                            <div className='bg-hover-transparent' key={location.document.id}>
                                <ObjectMd
                                    title={location.document.title.en}
                                    description={location.document.description?.en}
                                    icon={<ReactSvg src={`${storageUrl}${location.document.iconUrl}`} className='icon-lg'/>}
                                    onSelect={() => {}}
                                    width='w-full'
                                />

                            </div>
                        ))}

                    </div>
                </div>
            )}

        </div>
    </Spin>
  
  )
}
