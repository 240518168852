

import CardWithWebsiteLayout from '../WithWebsite/Layout'
import { useRef } from 'react'
import Description from '../WithWebsite/Description'

type Props = {
  sponsor: any,
  action?:() => void,
  active?:boolean
}
export default function SponsorWithMinuature({ sponsor , action, active} : Props) {
  const elRef = useRef()
    const OpenPartnerWindow = () => {
      if(action){
        action()
      }else{

      }
  }
  return (
    <div className={` ${active && 'bg-skin-fill-inverted text-skin-inverted'} rounded flex space-x-3`}>
      <CardWithWebsiteLayout
          item={{
            title: <p className={`${!active ? '!text-main-base' : 'text-white' }`}>{sponsor.title}</p>,
            image: sponsor.logo
          }}
        
          imgClassName={`h-[190px] w-full `}
          border
          active={active}
        />
        <Description
          title={sponsor.name}
          description={sponsor.description.en}
          website={sponsor.website}
          type='Sponsor'
          active={!!active}
        />
 
    </div>
  )
}
