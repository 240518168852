import React from 'react'
import Icon from '../../../icons/Icon'

type Props = {
    type:string, title:string, description:string, website:string, country?:string, active:boolean
}
export default function Description({type, title, description, website, country, active}:Props) {
  return (
    <div className='w-[157px] py-1 pr-1.5 leading-[19px]'>
      <div className='flex space-x-2 items-center'>
          <p className={`text-muted}`}>{type} {country}</p>

      </div>
          <p className='font-bold'>{title}</p>
          <p className={`ellipsis-3 ${active? 'text-inherit' : 'text-muted '}`}>{description}</p>
          <div className='flex space-x-1.5 mt-2'>
            <button className='p-1 rounded bg-hover-transparent'>
              <Icon name='OpenWindow' className='icon-mini'/>
            </button>
            <button className='p-1 rounded bg-hover-transparent disabled:fill-skin-muted' disabled>
              <Icon name='Chat' className='icon-mini'/>
            </button>
            <button className='p-1 rounded bg-hover-transparent disabled:fill-skin-muted' disabled>
              <Icon name='Card' className='icon-mini'/>
            </button>
          </div>
        </div>
  )
}
