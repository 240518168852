import { Pagination } from "antd";
import Divider from "../Divider";


type Props = {
    totalHits:{webresources: number | null , pubs: number | null, partnerPubs: number|null},
    setPaginate:(data:{offset?:number, limit?:number, page?:number})=> void,
    pagination:{offset:number, limit:number, page:number},
    disabled:boolean,
    hidePanel:boolean,
    allowPaginate: {webresources: boolean, pubs:boolean, partnerPubs:boolean}
}
export default function PaginationComponent(props:Props) {
 

  const handlePginate = (page:number, pageSize:number) => {
    if (page !== 0) {
      props.setPaginate({ offset: (page - 1) * pageSize, limit: pageSize, page: page })
    } else {
      props.setPaginate({ offset: pageSize, limit: pageSize, page: page })
    }
  }
  return (
    <div className="flex w-full h-full overflow-hidden bar-h-mini bg-bars text-white ">
        <div className={`flex-1 h-full z-10 relative transition-all duration-500`}>
          <div className="flex items-center space-x-7 h-full px-2">
            {!props.disabled && (
              <>
              {props.totalHits.pubs && props.totalHits.webresources && (props.totalHits.pubs > 0 || props.totalHits.webresources >0) ? (
                <>
                  <div className="flex space-x-3 items-center">
                    <p className="text-xs before:py-0 cursor-context-menu">{props.pagination.limit < props.totalHits.pubs ? props.pagination.limit + 5 : props.totalHits.pubs} displayed publication</p>
                    <Divider/>
                    <p className="text-xs cursor-context-menu">{props.totalHits.pubs + props.totalHits.webresources} total</p>
                  </div>
                  {props.totalHits.pubs > 0  && props.allowPaginate.pubs && (
                    <Pagination current={props.pagination.page} size="small" total={props.totalHits.pubs} showSizeChanger={false} onChange={handlePginate} pageSizeOptions={[7, 14, 20, 50]} pageSize={props.pagination.limit} style={{color:'#fff'}} className="text-xs text-white"/>
                  )}
                
                
                </>

              ) : <></>}
              {props.totalHits.pubs && !props.totalHits.webresources && props.totalHits.pubs>0 ? (
                <>
                  <div className="flex space-x-3 items-center">
                    <p className="text-xs before:py-0 cursor-context-menu">{props.pagination.limit < props.totalHits.pubs ? props.pagination.limit : props.totalHits.pubs} displayed publication</p>
                    <Divider/>
                    <p className="text-xs cursor-context-menu">{props.totalHits.pubs} total</p>
                  </div>
                  {props.totalHits.pubs > 0  && props.allowPaginate.pubs && (
                    <Pagination current={props.pagination.page} size="small" total={props.totalHits.pubs} showSizeChanger={false} onChange={handlePginate} pageSizeOptions={[7, 14, 20, 50]} pageSize={props.pagination.limit} className="text-xs"/>
                  )}
                
                </>

              ): <></>}
               {props.totalHits.webresources && !props.totalHits.pubs && props.totalHits.webresources>0 ? (
                <>
                  <div className="flex space-x-3 items-center">
                    <p className="text-xs before:py-0 cursor-context-menu">{props.pagination.limit < props.totalHits.webresources ? props.pagination.limit : props.totalHits.webresources} displayed web pages</p>
                    <Divider/>
                    <p className="text-xs cursor-context-menu">{props.totalHits.webresources} total</p>
                  </div>
                  {props.totalHits.webresources > 0 && props.allowPaginate.webresources && (
                    <Pagination current={props.pagination.page} size="small" total={props.totalHits.webresources} showSizeChanger={false} onChange={handlePginate} pageSizeOptions={[7, 14, 20, 50]} pageSize={props.pagination.limit} className="text-xs"/>
                  )}
                
                </>

              ): <></>}
              {props.totalHits.partnerPubs && props.totalHits.partnerPubs>0 ? (
                <>
                  <div className="flex space-x-3 items-center">
                    <p className="text-xs before:py-0 cursor-context-menu">{props.pagination.limit < props.totalHits.partnerPubs ? props.pagination.limit : props.totalHits.partnerPubs} displayed web pages</p>
                    <Divider/>
                    <p className="text-xs cursor-context-menu">{props.totalHits.partnerPubs} total</p>
                  </div>
                  {props.totalHits.partnerPubs > 0 && props.allowPaginate.partnerPubs && (
                    <Pagination current={props.pagination.page} size="small" total={props.totalHits.partnerPubs} showSizeChanger={false} onChange={handlePginate} pageSizeOptions={[7, 14, 20, 50]} pageSize={props.pagination.limit} className="text-xs"/>
                  )}
                
                </>

              ): <></>}
              
              </>

            )}
            </div>
       
        </div>
    </div>
  )
}
