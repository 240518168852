import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router';
import { Highlight, Publication } from '../../../../types';
import { useAppContext } from '../../../../context/appContext';
import useNavigateTo from '../../../../hooks/useNavigateTo';
import { searchPublications } from '../../../../services/search';
import { renderHeightStyle } from '../../../../utils/utils';
import Collapse, { Panel } from '../../../../components/shared/Collapse';
import Publications from '../../../../components/shared/publications';

export default function AllPublicationsUi({id, prefix} : {id:string, prefix:string}) {
    const [publications, setpublications] = useState<{document:Publication, highlight:Highlight}[]>([]);
    const { containerRef, query, selectedPubType, setTotalHits, pagination, setPaginate, totalHits } = useAppContext()
    const { goTo } = useNavigateTo()
    // useHandleLimits({type: 'publication', windowHeight: containerRef?.current?.clientHeight, setPaginate, resourcesWidth:containerRef?.current?.clientWidth})
    const search = async (q:string) => {
        const res:any = await searchPublications({volumeId:id, q:q.length > 1 ? q : '' ,type:selectedPubType,  page:pagination.page, limit:pagination.limit, offset:pagination.offset})
        setpublications(res.hits)
        setTotalHits(res.found)
    

    }
    useEffect(() => {
        (async () => {
              
                await search(query)

               
        
        })()
    }, [query, selectedPubType?.value, pagination.page, pagination.limit, pagination.offset, id]);



    
  return (
    <div className='flex'>

        <div className="pt-[14px] overflow-auto flex-1" style={renderHeightStyle(containerRef?.current?.clientHeight)}>
            <Collapse defaultActiveKey={['publications']}>
                <>
                
        
                    <Panel key={'publications'} header={<p className='text-groupe'>{totalHits.pubs} publications</p>}>
                        <div className="pl-[19px]">
                            {publications && publications.length>0 ? <>
                                <Publications publications={publications} handlePublicationSelection={(publication) => goTo(`${prefix}/all/publications/${publication.document.id}`, {state: {item:publication, url: publication.document.website}})} /> 
                        
                            </> : <p className='pl-2.5'>No publication </p>}
                            
                        </div>
                    </Panel>
                
                </>
            </Collapse>

        </div>
        <div className="w-[400px]">
            <Outlet/>

        </div>
    </div>
  )
}
