import { createContext, useContext, useMemo, useRef, useState } from "react";
import { Server } from "../types";

type States = {
  query: string,
  totalHits: {webresources:number | null, pubs:number | null, partnerPubs:number | null},
  selectedPubType : {value: string, icon:string, type:'type' | 'groupe', title:string },
  groupBy: {
    paidPubs: boolean,
    webresources:boolean
  },
  domain:Server | null,
  pagination: {page: number, limit:number, offset:number},
   
    isCleared: boolean,
   WindowHeight:number,
   resourcesWidth:number,

   stats: {volumes: number, publications: number},
  //  detailsMode: 'url' | 'object'
   
}
type Functions = {
  setQuery: (query:string) => void,
  setSelectedPubType: (data:{ value: string, icon: string, type:'type' | 'groupe', title:string }) => void,
  setTotalHits: (value:{webresources?:number | null, pubs?:number | null, partnerPubs?:number | null}) => void,
  setGroupBy: (data: {webresources?: boolean, paidPubs?:boolean})=> void,
  setPaginate: (data:{page?: number, limit?:number, offset?:number}) => void,
  clearSearch: () => void,
 
  setWindowHeight: (value:number) => void,
  setresourcesWidth: (value:number) => void
  setDomain: (value:Server|null) => void,
  setStats: (data:{volumes: number, publications: number}) => void,
  containerRef:any,
  rootRef:any,
  // setDetailsMode: (value:'url' | 'object') => void

}
type Props = States & Functions
const AppContext = createContext<Props>({} as Props);

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children } : { children : JSX.Element}) => {
  const [state, setState] = useState<States>({
    query: '',
    totalHits: {webresources:null, pubs:null, partnerPubs: null},
    domain: null,
    selectedPubType : {value: '', icon: '', type:'type', title:''},
    groupBy:{
      webresources: true, 
      paidPubs: false
    },
   
    pagination: {page:1, offset:0, limit:15},
    isCleared:false,
  
   WindowHeight:500,
   resourcesWidth:500,

   stats: {volumes: 0, publications: 0},


  //  detailsMode: 'object'

  })
  
  const containerRef = useRef()
  const rootRef = useRef()

  const value = useMemo(() => {
    

    const setQuery = (query:string) =>{
      setState((prev) => ({
        ...prev,
        query,
        pagination: {page:1, offset:0, limit:prev.pagination.limit}
      }))
    }


    const setPaginate = (data:{page?: number, limit?:number, offset?:number}) =>{
      setState((prev) => ({
        ...prev,
        pagination: {...prev.pagination,...data},
      }))
    }

  
    const clearSearch = () => {
      setState((prev) => ({
        ...prev,
        query: '',
        // expandedPanel:['partners', 'publications', 'childrens', 'owner','sites'],
        selectedPubType: {value:'', icon:'', type: 'type', title:''},
        pagination: {page:1, offset:0, limit:prev.pagination.limit}
      }))
    }

    const setSelectedPubType = (data:{ value: string, icon: string  , type:'type' | 'groupe', title: string}) => {
      setState((prev) => ({
        ...prev,
       selectedPubType: data,
      //  groupBy:{
      //   webresources: prev.groupBy.webresources,
      //   paidPubs:false
      //  },
       pagination: {page:1, offset:0, limit:prev.pagination.limit}
      }))
    }

    const setTotalHits = (value:{webresources?:number | null, pubs?:number | null, partnerPubs?:number | null}) => {
      setState((prev) => ({
        ...prev,
       totalHits: {...prev.totalHits,...value}
      }))
    }

    


    const setGroupBy = (data: {webresources?: boolean, paidPubs?:boolean})=> {
      setState((prev) => ({
        ...prev,
        groupBy: {...prev.groupBy ,...data},
        // selectedPubType:{value: '', icon: '', type:'type'},
        // pagination: {page:1, offset:0, limit:prev.pagination.limit}
      }))
    }


  



    const setWindowHeight = (value:number) => {
      setState((prev) => ({
        ...prev,
        WindowHeight:value
      }))
    }

    const setresourcesWidth = (value:number) => {
      setState((prev) => ({
        ...prev,
        WindowHeight:value
      }))
    }

    const setStats= (data:{volumes: number, publications: number}) => {
      setState((prev) => ({
        ...prev,
        stats: data
      }))
    }

    const setDomain= (data:Server|null) => {
      setState((prev) => ({
        ...prev,
        domain: data
      }))
    }
   






  //  const setDetailsMode = (value: 'url' | 'object') => {
  //   setState((prev) => ({
  //     ...prev,
  //     detailsMode: value
  //   }))
  //  }
    return {
      ...state,
      setWindowHeight,
      setPaginate,
      setTotalHits,
      setSelectedPubType,
      clearSearch,
      setQuery,
      setStats,
      setresourcesWidth,
      containerRef,
      rootRef,
      setGroupBy,
      setDomain
    }
  }, [state])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};