
import { useParams } from 'react-router';
import AllPublicationsUi from './AllPublicationsUi';

export default function AllPublicationsLayout() {
    const params = useParams()


    
  return (
    <AllPublicationsUi
    
        prefix=''
        id={params.volume ? params.volume : params.serverId!}
    />
  
  )
}
