import { useEffect } from "react"
import { useAppContext } from "../context/appContext"

type Props = {
    displayMode:any,
    disabled:boolean
}
export const useHandleLimits= ({displayMode, disabled }:Props) => {
    const { setPaginate } = useAppContext()
    useEffect(() => {
        function handleResize() {
            if(!disabled){
                const limit = Math.round(((window.innerHeight)*0.9-150)/(displayMode === 'OneLine' ? 30 :75/2)) - 2
       
                if(limit > 0){
                  setPaginate({limit})
                }else{
                  setPaginate({limit:0})
                }
                

            }

        
    }

         handleResize()
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, [displayMode, disabled])


    return{

    }
}