import React, { useRef, useState } from 'react'
import TreeComponentWorld from './tree/TreeComponentWorld'
import { iconType, Server, VolumeSearch } from '../../../../../types';



type Props = {
    handleLocationSelection: (node:any) => void, 
    selectedKey:string,
    datacenter: Server | null, 
    settree: (data:Server[] | null) => void,
    tree: Server[] | null,
}


export default function Sidebar({handleLocationSelection, selectedKey, datacenter, settree, tree }: Props) {

    const containerRef = useRef<any>()

   

    
    
 
  
    
  return (
    <div className='modules h-full  '>
      
        <div className="space-y-4 overflow-auto height-sidebar  border-r border-main h-full pb-layout mr-[9px] pt-5" ref={containerRef}>
         
          <div className="pl-[8px]">
          
      
              <TreeComponentWorld
              handleLocationSelection={handleLocationSelection}
              selectedKey={selectedKey}
              // server={server}
              datacenter={datacenter}
              settree={settree}
              tree={tree}
              // noType={noType}
            />
          
        

          </div>
        

        </div>
        
        
      
    </div>
  )
}
