import React from 'react'
import WebResourcesGroup from './grouped/webResources'
import { Highlight, Publication } from '../../../../../types'
import { useSelectionHandlers } from '../../../../../hooks/useSelectionHandler'
import Publications from '../../../../../components/shared/publications'


type Props = {
    suffix: string,
    webResources:{document:Publication, highlight:Highlight}[],
    groupBy: {webresources: boolean, paidPubs: boolean} | undefined,
    searchon:boolean,
    totalHits: { pubs: number | null, webresources:number | null} ,
    website?:string

}
const WebResources = React.memo(({ groupBy, searchon, totalHits, webResources, suffix, website} : Props) => {
    const { handleSelectPublication, handleSelectGroupedWebresources} = useSelectionHandlers(suffix)
    
  return (
    <div className={`space-y-2 ${!(groupBy?.paidPubs || !groupBy?.webresources || !searchon) && 'mt-10'}`}>
    {(groupBy?.paidPubs || !groupBy?.webresources || !searchon) ? <></>:<p className="text-groupe">{searchon ? ` ${totalHits.webresources} webpage found` : 'Latest web resources'}</p> }
     <div className='-ml-[20px]'>
       {
         groupBy?.webresources ? (
           <>
           {!groupBy.paidPubs && (

           <WebResourcesGroup searchOn={searchon} handleSelectGroupedWebresources={handleSelectGroupedWebresources} publications={webResources} handlePublicationSelection={(item, type?:'website') => handleSelectPublication(item, type ??'webresource')} author={false}/> 
           )}
           </>

         ): (
           <div className='pl-[15px]'>
              <div className='space-y-2'>
                <p className="text-groupe">{searchon ? ` ${totalHits.webresources} webpage found in ${website}` : website}</p>
                <Publications  publications={webResources} handlePublicationSelection={(item) => handleSelectPublication(item, 'webresource')}/>
              </div>
           </div>
         )
       }

     </div>
   </div>
  )
}
)
export default WebResources