import React from 'react';

import { useParams } from 'react-router-dom';
import { PartnerType } from '../../../../types';
import SponsorWithMinuature from '../../../../components/shared/cards/sponsors/SponsorWithMinuature';


type Props = {
  sponsors: PartnerType[] | null;
  suffix: string;
};

const PartnersAndSponsors= React.memo(({  sponsors, suffix }: Props) => {
  const params = useParams();
  // const { handleSelectPartner } = useSelectionHandlers(suffix)

  return (
    <div className='space-y-2'>
      <p className='text-groupe'>Sponsors</p>
      <div className='flex flex-wrap ml-[-7px] gap-12'>
        {/* {partners?.map(part => (
          <div
            key={part.id}
            className={`w-max rounded cursor-pointer ${part.id === params.partnerId ? '' : 'bg-hover-transparent'}`}
            onClick={() => {}}
          >
            <PartnerWithWebsite active={params.partnerId === part.id} partner={part} />
          </div>
        ))} */}
        {sponsors?.map(sponsor => (
          <a
            key={sponsor.id}
            className={`w-max hover:!text-inherit rounded cursor-pointer ${sponsor.id === params.partnerId ? '' : ''}`}
            onClick={() => {}}
            href={sponsor.webResources?.website??sponsor.website}
            target='_blank'
          >
            <SponsorWithMinuature active={params.partnerId === sponsor.id} sponsor={sponsor} />
          </a>
        ))}
      </div>
    </div>
  );
})

export default PartnersAndSponsors;
