import React, { useEffect, useMemo } from 'react'

import PublicationsLink from './publications/PublicationsLink'
import WebResources from './publications/WebResources'
import { useAppContext } from '../../../../context/appContext'
import { Highlight, OwnerType, PartnerType, Publication } from '../../../../types'
import PartnersAndSponsors from './Partners'
import Owner from './Owner'
import { useHandleLimits } from '../../../../hooks/useHandleLimits'
import PartnerPublications from './publications/PartnerPublications'


type Props = {
    // availableLocations: {document :VolumeSearch}[] | null,
    suffix: string,
    publications: undefined | {document:Publication, highlight:Highlight}[],
    webResources: undefined | {document:Publication, highlight:Highlight}[],
    partnerPublications?:{document:Publication, highlight:Highlight}[] | undefined,
    isLoaded:boolean,
    sponsors: PartnerType[] | null
    allowSearch: boolean,
    groupBy?:{
      webresources:boolean,
      paidPubs: boolean
    },
    owner?:OwnerType | undefined,
    showApps?:boolean,
    all?:string,
    home?:boolean,
    partnerId?:string
}


export default function ResourcesLayout({sponsors, suffix,webResources, publications, allowSearch, groupBy, owner, showApps, all, isLoaded, partnerPublications, partnerId} : Props) {
    // const { displayMode, barActivatedFields, setAll} = useUIContext()
    const {query, selectedPubType, totalHits,  } = useAppContext()
    useHandleLimits({displayMode: 'OneLine', disabled: !all && !selectedPubType.value})
    // useEffect(() => {
    //   if(all){
        
    //     setAll(all)
    //   }else{
       
    //     setAll('')
    //   }
    // }, [all]);
   
    const isSearchActive =  !!query || !!selectedPubType.value
    const showPartnersAndSponsors = (!isSearchActive || !allowSearch) && (sponsors && sponsors?.length > 0)
    const showOwner = (!isSearchActive || !allowSearch) && owner?.website
 
    const showPublications = isLoaded && allowSearch && publications && publications.length>0
    const showPartnerPublications = isLoaded && allowSearch && partnerPublications && partnerPublications.length>0 && (!selectedPubType.value || all)

    const showWebResources = isLoaded && webResources && webResources.length>0 && !selectedPubType.value
    return (
        <div className="pl-[24px] pt-[14px] overflow-auto height-explorer space-y-[60px]" >
             
            {showPartnersAndSponsors && (
              <PartnersAndSponsors
                sponsors={sponsors}
                suffix={suffix}
                
              />


            )}
    
            
            {showOwner && (
                <Owner
                  owner={owner}
                  suffix={suffix}
                />


            )}
            
          
            <div>
            {showPartnerPublications && (
          
                <PartnerPublications
                  publications={partnerPublications}
                  totalHits={totalHits}
                  searchon={isSearchActive}
                  suffix={suffix}
                  partnerId={partnerId}
                />


            )}
            {showPublications && (
          
                <PublicationsLink
                  publications={publications}
                  webResources={webResources}
                  groupBy={groupBy}
                  totalHits={totalHits}
                  searchon={isSearchActive}
                  selectedPubType={selectedPubType}
                  suffix={suffix}
                />
    
  
            )}
            {showWebResources && (
              <WebResources
                webResources={webResources}
                searchon={isSearchActive}
                suffix={suffix}
                groupBy={groupBy}
                totalHits={totalHits}
                website={all}
              />

              )}
            
            </div>
          

      

          
  
        </div>


    )
}
