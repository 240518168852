
import { useParams } from 'react-router';
import AllPublicationsUi from './AllPublicationsUi';

export default function AllPublicationsLayoutEngines() {
    const params = useParams()


    
  return (
    <AllPublicationsUi
    
        prefix={`/engines/${params.engineType}/${params.engineId}`}
        id={params.engineId!}
    />
  
  )
}
