import { Spin, Tree } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { ReactSVG } from 'react-svg';
import OneLine from '../../../../../../components/shared/object/oneLine';
import Icon from '../../../../../../components/icons/Icon';
import ReactSvg from '../../../../../../components/shared/ReactSvg';
import { iconType, Server } from '../../../../../../types';








const mapTree = (tree:any[]) =>{
    return tree.map(loc => {
        loc.path = loc.key
        
    
      if(loc.children) mapTree(loc.children)
        
      return loc
    })
  }
type Props = {

    defaultExpandedKeys?:any[],
    handleLocationSelection: (node:any) => void, 
    selectedKey:string,
    tree: any[] | null,
    
}


export default function TreeLayout({ handleLocationSelection, selectedKey, tree}: Props) {

    const ref = useRef<any>()
    const [autoExpandParent, setautoExpandParent] = useState(true)
    const [expandedKeys, setexpandedKeys] = useState<any[]>(['0', '1', '2', '3', '4', '5', '/'])
    const [height, setHeight] = useState(
        typeof window === "undefined" ? 0 : window.innerHeight
    );
    const updateDimensions = () => {
        if (typeof window !== "undefined") {
          setHeight(window.innerHeight);
        }
    };


    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("resize", updateDimensions);
            return () => window.removeEventListener("resize", updateDimensions);
        }
        }, [updateDimensions]);
 
  useEffect(() => {
    if (ref?.current && tree && tree.length > 0) {
      setTimeout(() => {
        ref?.current?.scrollTo({
          key: selectedKey,
          align: "auto",
          behavior: "smooth",
          offset: 100,
        });
      }, 200);
    }
  }, [selectedKey, ref?.current, height]);

  useEffect(() => {
    setautoExpandParent(true)
    setexpandedKeys(prev => ([...prev, selectedKey]))
  }, [selectedKey, tree])



  return (
    <>
  
            {tree && tree?.length > 0 && (

                  <Tree
                    ref={ref}
                    // selectedKeys={[location.state?.key]}
                        treeData={tree ? mapTree(tree) : []}
                        selectedKeys={[selectedKey]}
                        autoExpandParent={autoExpandParent}
                        expandedKeys={expandedKeys}
                        onExpand={(expandedKeys) => {
                            setexpandedKeys(expandedKeys)
                            setautoExpandParent(false);
                        }}
                        // height={
                        //     containerRef?.current
                        //     ? containerRef.current.clientHeight
                        //     : 900
                        // }
                            className='sidebar bg-transparent w-full pb-5'
                        switcherIcon={({ expanded } : any) =>
                            expanded ? (
                            <ChevronDownIcon className="w-3.5 h-3.5" />
                            ) : (
                            <ChevronRightIcon className="w-3.5 h-3.5" />
                            )
                        }
                        titleRender={(location: any) => (
                                
                            <OneLine
                                noHover
                                draggable
                                onDragStart={e => e.dataTransfer.setData('item', JSON.stringify({title: location.title,icon: location.iconModule ? {src: location.iconModule , type:'icon'}:{src: location.iconUrl, type:'image'} ,id:location.id, docType:'volume'}))}
                                active={selectedKey===location.key}
                                dropdownProps={null }
                                className='!p-0 !bg-transparent hover:!bg-transparent transition-none '
                            >
                                <div className='flex justify-between items-center w-full'>
                                
                                    <div className={`flex space-x-2.5 items-center  ` }>
                                        

                                                <div className='relative w-[20px] h-[20px]'>
                                                    {location.iconType === 'icon' ? (
                                                        <Icon name={location.iconUrl} className='w-full h-full'/>
                                                    ) : (
                                                        <ReactSvg
                                                        src={location.iconUrl} 
                                                        className='w-full h-full'
                                                      
                                                        />

                                                    )}
                                                </div>
                                        
                                    
                                        <p className={`
                                        ${location.depth === 2 && 'max-w-[136px]'}
                                            ${location.depth === 3 && 'max-w-[145px]'}
                                            ${location.depth === 4 && (selectedKey === location.key ? 'max-w-[115px]' : 'max-w-[149px]')}
                                            ${location.depth === 5 && (selectedKey === location.key ? 'max-w-[84px]' : 'max-w-[117px]')}

                                        truncate`}>{location.title}</p>

                                    </div>
                                
                                </div>
                                

                            </OneLine>
                        

                    )}
                    
                    onSelect={(node,e) => {
                        handleLocationSelection(e.node)
                    }}
                    
                />
                
                )}

      
          
    
    </>
  )
}
