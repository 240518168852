import { storageUrl } from "../../../../../../../constants/apiRequests";
import Icon from "../../../../../../icons/Icon";
import DropDownMenu from "../../../../../menu";
import ReactSvg from "../../../../../ReactSvg";



type Props = {
    selectAction : (node:any) => void;
    selectedType:{value:string},
    typesList: any
}

export default function TypesDropdown({ selectedType, selectAction, typesList}:Props) {


  function mapTreemenu(tree:any, onselect:any) {
    return tree.map((node:any) => {
      
      if (node.children && node.children.length > 0) mapTreemenu(node.children,onselect)
      if(node.icon.src){
        node.iconUrl = `${storageUrl}${node.icon.src}`
        node.icon = 
          <div className={`relative icon-sm`}>
              <ReactSvg src={`${storageUrl}${node.icon.src}`} className='w-full h-full' />
          </div>
          
      }
    
  
      node.key = node.id
      node.value = node.id
        if(node.name_singular){
            node.label = node.name_singular.en
        }else{
            node.label = node.title.en
        }
    if(node.selectable !== false){
        node.onClick = () => onselect(node)

    }
     
    
      return node
    })
  }
  return (

        <DropDownMenu
          selectedKeys={[selectedType.value]}
          items={[...mapTreemenu(JSON.parse(JSON.stringify(typesList ? typesList?.filter((type:any) => type.id === 'popular')?.[0]?.children : [])),selectAction),{
            
              label:'Others',
              icon: <Icon name='FilterIcon' className="icon-sm"/>,
              disabled:true,
              key: 'others',
              children: [
             
              ]
          }]}
        
        />
  )
}

