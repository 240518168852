import React from 'react'
import GroupedPublications from './grouped/grouped'
import WebResourcesGroup from './grouped/webResources'
import { Highlight, Publication } from '../../../../../types'
import Publications from '../../../../../components/shared/publications'
import { useSelectionHandlers } from '../../../../../hooks/useSelectionHandler'



type Props = {
    suffix: string,
    publications: {document:Publication, highlight:Highlight}[],
    webResources: undefined | {document:Publication, highlight:Highlight}[],
    groupBy: {webresources: boolean , paidPubs: boolean } | undefined,
    searchon:boolean,
    totalHits: { pubs: number | null, webresources:number | null} ,
    selectedPubType: {value:string, icon:string, type:'groupe' | 'type'},

}
const PublicationsLink = React.memo(({ suffix, publications, webResources, groupBy, searchon, totalHits, selectedPubType } : Props) => {
    const { handleSelectPublication, handleSelectGroupedWebresources, handleSelectGroupedPublication} = useSelectionHandlers(suffix)
    return (
    <div className="space-y-2">
        <p className="text-groupe">
        
        {groupBy?.paidPubs ? '' : (
            searchon ? `${totalHits.pubs} publications found` : `Recently added`
        )}
        </p>
        <div className='ml-[-5px] h-full'>
        
            {(publications && publications?.length >0) ? (
            <>
            {groupBy?.paidPubs ? (
                <>
                <GroupedPublications
                publications={publications} 
                handlePublicationSelection={handleSelectPublication} 
                handleSelectGroupedPublication={handleSelectGroupedPublication}
                author={true}
                extraPanel={(groupBy.webresources && !selectedPubType.value && webResources && webResources.length > 0) ? {
                title: `Web resources (${totalHits.webresources})` ,
                element: <WebResourcesGroup searchOn={searchon} handleSelectGroupedWebresources={handleSelectGroupedWebresources} publications={webResources} handlePublicationSelection={(item, type) => handleSelectPublication(item, type?? 'webresource')} author={false}/> 
                } : undefined}
                />
            
                </>
            ) : (
                <Publications publications={publications} handlePublicationSelection={(item:{document:Publication, highlight?:Highlight}) => handleSelectPublication(item)} />

            )}
           
            </>
            ) :( 
            <p>No publication</p>
            )}
        
        </div>
    </div>
  )
}
)

export default PublicationsLink