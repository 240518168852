import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useAppContext } from '../context/appContext'

type Props = {
  extra?:string
} 
export default function useNavigateTo() {
    const navigate  = useNavigate()
    const params = useParams()
    const [searchParams] = useSearchParams()
    const goTo = (to :string,props:any, noQuery?:boolean, tab?:string) => {
      const query = searchParams.toString();
      navigate(`/widget/main/${params.datacenterId}/${params.serverId ?? ''}${params.type ? `/${params.type}` : ''}${params.type ? `/${params.volume}` : ''}${to}${!noQuery ? `?${query}` : ''}`, props )
    }
  return {
    goTo
  }
}
