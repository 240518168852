import React from 'react'
import GroupedPublications from './grouped/grouped'
import WebResourcesGroup from './grouped/webResources'
import { Highlight, Publication } from '../../../../../types'
import Publications from '../../../../../components/shared/publications'
import { useSelectionHandlers } from '../../../../../hooks/useSelectionHandler'



type Props = {
    suffix: string,
    publications: {document:Publication, highlight:Highlight}[],
    searchon:boolean,
    totalHits: { pubs: number | null, webresources:number | null, partnerPubs:number|null} ,
    partnerId?:string

}
const PartnerPublications = React.memo(({ suffix, publications, searchon, totalHits, partnerId } : Props) => {
    const { handleSelectPublication, handleSelectSeeAllPartnerPublications } = useSelectionHandlers(suffix)
    return (
    <div className={`space-y-2 ${partnerId && 'mb-10'}`}>
        <p className="text-groupe">
        
            {searchon ? `${totalHits.partnerPubs} articles found` : `Our recently added articles`}
       
        </p>
        <div className='ml-[-5px] h-full'>
        
            {(publications && publications?.length >0) ? (
            <>
         
             
                <Publications publications={publications} handlePublicationSelection={(item:{document:Publication, highlight?:Highlight}) => handleSelectPublication(item)} />
                {
                    partnerId && totalHits.partnerPubs && totalHits.partnerPubs> 7 && (
                        <button className='underline mt-3' onClick={() => handleSelectSeeAllPartnerPublications(partnerId)}>see all articles</button>

                    )
                }
           
            </>
            ) :( 
            <p>No publication</p>
            )}
        
        </div>
    </div>
  )
}
)

export default PartnerPublications