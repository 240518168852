import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { useAppContext } from '../../../context/appContext';
import useNavigateTo from '../../../hooks/useNavigateTo';
import LocationLayout from '../components/resourcesLayout/Layout';
import { useSearchParams } from 'react-router-dom';
import useGetPublications from '../../../hooks/useGetPublications';
import useGetWebResources from '../../../hooks/useGetWebResources';
import useGetSponsors from '../../../hooks/useGetCollaborators';

export default function ResourcesLayout() {
    const params = useParams()
    const { groupBy, selectedPubType } = useAppContext()
    const { goTo } = useNavigateTo()
    const [searchParams] = useSearchParams();
    
    const { webresources, isLoaded:isLoaded2 } = useGetWebResources({id:params.id!, groupBy:true})
    const [loading, setloading] = useState(false);
    const { sponsors, partners } = useGetSponsors({id:params.id!, suffix:'', coll:params.coll!, setloading:setloading})
    const { publications, isLoaded } = useGetPublications({id:params.id!, limit: 7, groupBy: !!searchParams.get('grouped'), extraFilter: (partners?.[0] && !selectedPubType.value) ? `&& roots:!=${partners[0].id}` : ''})
    const { publications:partnerPublications, isLoaded:isLoaded3 } = useGetPublications({id:partners?.[0]?.id, limit: 7, groupBy: false, partner:true})




  return (
    <>
    <LocationLayout
         layoutProps={
           {
             loading,
             collection: params.coll!,
             id: params.id!
           }
           
         }
         partnerPublications={partnerPublications}
         groupBy={groupBy}
         allowSearch={true}
         publications={publications}
         sponsors={sponsors}
         suffix={`${params.coll}/${params.id}/`}
         home
         webResources={webresources}
         isLoaded={isLoaded && isLoaded2 && isLoaded3}
         partnerId={partners?.[0]?.id}
         
         
    />
    {/* <ResourcesUi
        sponsors={sponsors}
        partners={null}
        publications={publications}
        volumes={volumes?.filter(v => v.document.id !== volume?.id)}
        prefix=''
        totalHits={totalHits}
        onSelect={(childVolume: VolumeSearch) => {
            if(volume?.organizationId && (childVolume.organizationId === volume?.organizationId)){
                goTo(`/volumes/${childVolume.id}/publications`, {state: childVolume})
            }else{
                goTo(`/volumes/${childVolume.id}`, {state: childVolume})

            }
        }}
        loading={false}
    
    /> */}
    {/* <div className="pt-[14px] overflow-auto flex-1" style={renderHeightStyle(containerRef?.current?.clientHeight)}>
        <Collapse defaultActiveKey={['publications', 'volumes', 'sponsors']}>
            <>
            {query.length  < 3 && sponsors && sponsors.length > 0 && (
                <Panel key={'sponsors'} header={<p className='text-groupe'>Sponsors</p>}>
                        <div className='pl-[14px] '>
                            <div className="flex flex-wrap gap-x-9">
                                    <>
                                    {sponsors.map(sponsor => (
                                        <div key={sponsor.id} className='bg-hover-transparent  hover-lg'>
                                            <SponsorCard sponsor={sponsor}/>
                                        </div>
                                        ))}
                                
                                    </>
                            </div>
                         
                        
                        </div>
                    </Panel>

            )}
            {volumes && volumes.length>0 && (
                <Panel key={'volumes'} header={<p className='text-groupe'>Recently added volumes</p>}>
                    <div className='pl-[14px] '>
                        <div className="flex flex-wrap gap-x-9">
                    
                                {volumes.filter(v => v.document.id !== volume?.id).map(childVolume => (
                                        <ObjectWithDropdown
                                        title={childVolume.document.title.en}
                                        overlay={<ResourceMenu
                                            items={{shortcut:true}}
                                
                                        />}
                                        icon={<div className='relative w-full h-full '>
                                            <ReactSvg src={`${storageUrl}${childVolume.document.iconUrl}`} className='w-full h-full'
                    
                                            />
                                        
                                            
                                        </div>}
                                        key={childVolume.document.id}
                                        id={childVolume.document.id!}
                                        description={<p className='truncate'>{childVolume.document.breadcrumbs?.[0]?.en!}</p>
                                            
                                        
                                            
                                        }
                                        active={childVolume.document.id === params.volumeId}
                                        onSelect={() => {
                                            if(volume?.organizationId && (childVolume.document.organizationId === volume?.organizationId)){
                                                goTo(`/volumes/${childVolume.document.id}/publications`, {state: childVolume.document})
                                            }else{
                                                goTo(`/volumes/${childVolume.document.id}`, {state: childVolume.document})

                                            }
                                        }}
                                    />
                                    ))}
                            
                     
                        </div>
                        {volumes && totalHits.volumes > 7 &&
                            <ButtonLg       
                                buttonProps={{
                                    onClick: () => goTo('/all/volumes', {})
                                }}
                            ><p>See all ({totalHits.volumes})</p></ButtonLg>
                        
                        }
                    
                    </div>
                </Panel>

            )}
                <Panel key={'publications'} header={<p className='text-groupe'>Recent added publications</p>}>
                    <div className="pl-[19px]">
                        {publications && totalHits.publications>0 ? <>
                            <Publications setRecheckPin={() => {}} publications={publications} handlePublicationSelection={(publication) => goTo(`/publications/${publication.id}`, {state: {item:publication, url: publication.website}})} /> 
                            {publications.length > 6 && (
                                <ButtonLg
                                
                                    buttonProps={{
                                        onClick: () => goTo('/all/publications', {})
                                    }}
                                ><p>See all ({totalHits.publications})</p></ButtonLg>

                            )}
                        </> : <p className='pl-2.5'>No publication yet</p>}
                        
                    </div>
                </Panel>
            
            </>
        </Collapse>

    </div> */}
  

    </>
  )
}
