import React, { useState } from 'react'
import Sidebar from '../components/worldLayout/sidebar'
import { useAppContext } from '../../../context/appContext';
import { Outlet, useNavigate, useParams } from 'react-router';

export default function WorldLayout() {
    const [selectedKey, setselectedKey] = useState('');
    const [tree, settree] = useState<any>([]);
    const { domain } = useAppContext()
    const params = useParams()
    const navigate = useNavigate()
    const handleSelecton = (node:any) => {
    
        navigate(`/widget/global/${params.datacenterId}/${params.coll}/${params.id}${node.key}`)
    }   
  return (
    <div className='flex'>
        <div className='w-[300px] overflow-auto height-with-2bars'>
            <Sidebar
                selectedKey={`/${params.volumeColl}/${params.volumeId!}`}
                tree={tree}
                settree={settree}
                datacenter={domain}
                handleLocationSelection={handleSelecton}

            
            />

        </div>
        <div className="flex-1">
            <Outlet/>
        </div>
    </div>
  )
}
