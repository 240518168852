import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import UrlMode from '../modes/Url'
import ObjectMode from '../modes/Object'
import { Spin } from 'antd'
import { Highlight, Publication } from '../../../../types'
import DetailsLayout from '../layout'
import { getPublication, getWebPage } from '../../../../services/search'
import { storageUrl } from '../../../../constants/apiRequests'
import Icon from '../../../icons/Icon'


export default function PublicationDetails() {
    const location = useLocation()
    const [item, setitem ] = useState<null | Publication>(null)
    const [highlight, sethighlight] = useState<null | Highlight>(null);
    const [url, seturl] = useState<null | string>(null)
    const params = useParams()
    const [loading, setloading] = useState(false)
    useEffect(() => {
      if(location.state){
        setitem(location.state.item)
        seturl(location.state.url)
        sethighlight(location.state.highlight)

      }else{
        if(params.publicationId || params.websitePublicationId){
          console.log('search publication')
          setloading(true)
          getPublication({id:params.publicationId??params.websitePublicationId!}).then((res:any) => {
            setitem(res)
            seturl(res.url)
            setloading(false)
            sethighlight(null)
          })

        }
        if(params.webpageId){
          setloading(true)
          getWebPage({id:params.webpageId}).then((res:any) => {
            setitem(res)
            seturl(res.url)
            setloading(false)
            sethighlight(null)
          })
        }
      }
    },[location.state, params.publicationId, params.webpageId, params.websitePublicationId])

  return (
    <DetailsLayout loading={loading}>
      <div className='h-full w-full'>
        <Spin spinning={loading}>
          {/* {mode === 'url' ? (

              url ? <UrlMode  url={url} /> : <></>
          ) : ( */}
            <div className={` overflow-auto h-[calc(90vh-44px)] `}>
              {item && (
                <ObjectMode 
                  thumb={item.thumbnail ? {
                      type:'slide',
                      images: item.media ? [{src: item.thumbnail, type:'image'},...item.media] : [{src: item.thumbnail, type:'image'}]
                  }: {
                    type: 'icon',
                    icon:{type:'image', src:`${storageUrl}${item.publicationType.iconUrl}`}
                  }}

                  fields={
                    [
                      {
                        name:'Title',
                        value: (highlight && highlight.title?.[item.local] ? <p className='text-start' dangerouslySetInnerHTML={{__html: highlight?.title?.[item?.local]?.snippet!}}></p> : <p className='text-start'>{item?.title[item?.local]}</p>)
                      },
                      {
                        name:'Type',
                        value: item.publicationType.title?.en!,
                        center:true
                      },
                      ...item.description?.[item.local] ? [
                        {
                          name: 'Description',
                          // value:<p dangerouslySetInnerHTML={{__html: item.description[item.local]!}} style={{lineHeight:'1.6'}}></p>
                          value: (highlight && highlight.description?.[item.local] ? <p dangerouslySetInnerHTML={{__html: highlight?.description?.[item?.local]?.snippet!}} style={{lineHeight:'1.6'}}></p> : <p dangerouslySetInnerHTML={{__html: item.description[item.local]!}} style={{lineHeight:'1.6'}}></p>)

                        }
                      ]:[],
                      ...item.keywords?.[item.local]?.length > 0 ? [
                        {
                          name: 'Keywords',

                          value:<div className="flex-1 flex flex-wrap gap-1">
                            {(highlight && highlight.keywords?.[item.local]) ? (
                              highlight.keywords?.[item.local]!.map((keyword) => 
                                <p key={keyword.snippet} className='px-3 py-1.5 rounded-full border border-main ' dangerouslySetInnerHTML={{__html: keyword.snippet}}></p>
                              ) 
                            ) : (
                              item.keywords[item.local].map((keyword : string) => <p key={keyword} className='px-3 py-1.5 rounded-full border border-main '>{keyword}</p>) 

                            )}
                          </div>,
                          

                        }
                      ]:[],
               
                        {
                            name: '',
                            value: <a href={item.url} target='_blank' className='bg-skin-fill-inverted text-skin-inverted py-1.5 px-4 rounded flex space-x-2 items-center w-max '>
                              <p className='!text-inherit'>Open</p>
                              {/* <Icon className='icon-sm' name='Open'/> */}
                            </a >
                        }
                 
                
                    ]
                  }
                />

              )}

            </div>
          {/* )} */}

        </Spin>
      
      
      </div>

    </DetailsLayout>
  )
}
