import React, { useRef } from 'react'
import CardWithWebsiteLayout from '../WithWebsite/Layout'
import Description from '../WithWebsite/Description'

type PartnerType = {
  owner: any,
  imgHeight?:string,
  active?:boolean,
}
export default function OwnerWithWebsite({ owner, imgHeight,active } : PartnerType) {
  const elRef = useRef<any>()

 
  return (
    <div className={` ${active && 'bg-skin-fill-inverted text-skin-inverted'} rounded flex space-x-3`}>
      <CardWithWebsiteLayout
        item={{
          title: <p className={`${!active ? '!text-main-base' : 'text-white' }`}>{owner.name}</p>,
          image: owner.logo
        }}
      
        imgClassName={`h-[190px] w-full ${imgHeight && imgHeight}`}
        border
        active={active}
      />
      <Description
          title={owner.name}
          description={owner.description.en}
          website={owner.website}
          type='Owner'
          active={!!active}
        />
    
    </div>
  )
}
