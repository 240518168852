import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppContext } from '../../../../context/appContext'
import useGetWebResources from '../../../../hooks/useGetWebResources'
import { getPublication } from '../../../../services/search'
import LocationLayout from '../../components/resourcesLayout/Layout'


export default function Website() {
    const {  groupBy } = useAppContext()
    // const {disabled} = useUIContext()
    const params = useParams()
    const [mainWebsite, setmainWebsite] = useState<string | undefined>(undefined);
    const { webresources, isLoaded } = useGetWebResources({id: params.id ? params.id : params.serverId!, websiteId: params.websiteId!, groupBy: false})
    
    useEffect(() => {
      
      (async() => {
        if(!params.websiteId) return
        const res:any = await getPublication({id: params.websiteId})
        const url = res?.url 
        if(url){
          const hostname = new URL(url)
          if(hostname?.hostname){
            setmainWebsite(hostname?.hostname)
          }else{
            setmainWebsite(res?.title?.[res?.local])
          }
        }else{
          setmainWebsite(res?.title?.[res?.local])

        }
      })()
    }, [params.websiteId]);
 

    
  return (
    <LocationLayout
      sponsors={null}
      publications={undefined}
      webResources={webresources}
      groupBy={groupBy}
      suffix={`${params.coll}/${params.id}/webresources/${params.websiteId}/`}
      layoutProps={{
        loading:!isLoaded,
        collection: params.coll!,
        id: params.id!
      }}
      all={mainWebsite}
      isLoaded={isLoaded}


    
    />
  )
}
