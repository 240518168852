import React, { useEffect, useState } from 'react'
import { VolumeSearch } from '../types';
import { searchLocations } from '../services/search';

type Props = {
    id: string,
    extraFilter?:string
}
export default function useGetLocations({id, extraFilter} :Props) {
    const [locations, setlocations] = useState<{document:VolumeSearch}[]>([]);
    const [totalHits, settotalHits] = useState(0);

    useEffect(() => {
        
        (async () => {
            const res:any = await searchLocations({
                q: '*',
                id,
                limit: 7,
                sort: 'created_at:desc',
                extraFilter
            })
            setlocations(res.hits)
            settotalHits(res.found)
        })()
    }, [id, extraFilter]);
  return {
    locations,
    totalHits
  }
}
